import * as React from 'react';
import { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class Content extends Component {
    constructor() {
        super();
        this.state = {
            show: false
        };
    }

    render() {
        return (
            <div className={`${this.props.show ? 'd-block' : 'd-none'}`}>
                <Container className='site-map px-0'>
                    <Row>
                        <Col xs={10} className='ml-auto mr-auto content'>
                            <div className='muted-yellow-dark header'>
                                <h1 className='font-inherit mb-0'>Site Map</h1>
                            </div>

                            <div className='link-section'>
                                <span className='title'>About Us</span>
                                <a href='/about-us' data-analytics-parent-category='About Us'>
                                    What We Live For<sup>&reg;</sup>
                                    <i className='fa fa-chevron-right'></i>
                                </a>
                                <a
                                    href='/community-involvement/community-commitments'
                                    data-analytics-parent-category='About Us'
                                >
                                    Community Commitments
                                    <i className='fa fa-chevron-right'></i>
                                </a>
                            </div>

                            <div className='link-section'>
                                <span className='title'>HIV Landscape</span>
                                <a
                                    href='/landscape/history-of-hiv/'
                                    data-analytics-parent-category='HIV Landscape'
                                >
                                    History of the HIV Epidemic
                                    <i className='fa fa-chevron-right'></i>
                                </a>
                                <a
                                    href='/landscape/state-of-epidemic/'
                                    data-analytics-parent-category='HIV Landscape'
                                >
                                    State of the HIV Epidemic
                                    <i className='fa fa-chevron-right'></i>
                                </a>
                                <a
                                    href='/landscape/ehe/'
                                    data-analytics-parent-category='HIV Landscape'
                                >
                                    Plans for Ending the HIV Epidemic
                                    <i className='fa fa-chevron-right'></i>
                                </a>
                                <a
                                    href='/landscape/hiv-care-continuum/'
                                    data-analytics-parent-category='HIV Landscape'
                                >
                                    HIV Care Continuum
                                    <i className='fa fa-chevron-right'></i>
                                </a>
                                <a
                                    href='/landscape/hiv-testing/'
                                    data-analytics-parent-category='HIV Landscape'
                                >
                                    HIV Testing
                                    <i className='fa fa-chevron-right'></i>
                                </a>
                            </div>

                            <div className='link-section'>
                                <span className='title'>Community Involvement</span>
                                <a
                                    href='/community-involvement/community-commitments'
                                    data-analytics-parent-category='Community Involvement'
                                >
                                    Community Commitments
                                    <i className='fa fa-chevron-right'></i>
                                </a>
                                <a
                                    href='/championsofchange'
                                    data-analytics-parent-category='Celebrating Black Women Leaders'
                                >
                                    Champions of Change: Black Women Leaders
                                    <i className='fa fa-chevron-right'></i>
                                </a>
                                <a
                                    href='/community-involvement/blind-angels/'
                                    data-analytics-parent-category='Community Involvement'
                                >
                                    Blind Angels
                                    <i className='fa fa-chevron-right'></i>
                                </a>
                                <a
                                    href='/community-involvement/voices-of-strength/'
                                    data-analytics-parent-category='Community Involvement'
                                >
                                    Voices of Strength
                                    <i className='fa fa-chevron-right'></i>
                                </a>
                                <a
                                    href='/community-involvement/social-toolkit/'
                                    data-analytics-parent-category='Community Involvement'
                                >
                                    Social Media Toolkit
                                    <i className='fa fa-chevron-right'></i>
                                </a>
                                <a
                                    href='/community-involvement/events/'
                                    data-analytics-parent-category='Community Involvement'
                                >
                                    Events
                                    <i className='fa fa-chevron-right'></i>
                                </a>
                            </div>

                            <div className='link-section'>
                                <span className='title'>Deeper Dive</span>
                                <a
                                    href='/deeper-dive/content-index/'
                                    data-analytics-parent-category='Deeper Dive'
                                >
                                    Featured Articles
                                    <i className='fa fa-chevron-right'></i>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Content;
